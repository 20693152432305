.form-control {
    border-radius: 0.5em;
    border: 1px solid theme-color("primary");
    color: theme-color("primary");
}

.form-group {
	::placeholder {
		color:theme-color("primary");
	}
}

.frifeldt-recaptcha-form {
  .form-actions {
    margin-top:20px;
  }
  .recaptcha,
  label#recaptcha-error {
    opacity:0;
  }
  
  .ohnohoney {
    opacity:0;
    position: absolute;
    left: -9999px;
    label {
      margin:0;
    }
  }
}