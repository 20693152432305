.e-footer {
    font-size: $font-size-sm;
}

.e-footer-content {
    @include e-bg($footer-bg, $footer-color);
    @include e-text($footer-color, $footer-headings-color, $footer-link-color);
    padding-top: $spacer * 1.5;
    padding-bottom: $spacer * 1.5;
    svg {
        [id*="Filled_Icons"] {
            fill: $footer-color;
            opacity: 0.85;
        }
        [id*="Outline_Icons"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: $footer-color;
                opacity: 0.85;
            }
        }
    }
    .form-control {
        background-color: $footer-headings-color;
    }
}

// https://simpleicons.org/
// https://jakearchibald.github.io/svgomg/
.e-footer-icon-facebook {
    color: transparent;
    background-size: 90% 90%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg fill='#{$footer-social-icon-color}' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0'/%3E%3C/svg%3E"), "#", "%23");
}

.e-footer-icon-linkedin {
    color: transparent;
    background-size: 90% 90%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg fill='#{$footer-social-icon-color}' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 0 1-2.063-2.065 2.064 2.064 0 1 1 2.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z'/%3E%3C/svg%3E"), "#", "%23");
}

.e-footer-icon-googleplus {
    color: transparent;
    background-size: 90% 90%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg fill='#{$footer-social-icon-color}' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.635 10.909v2.619h4.335c-.173 1.125-1.31 3.295-4.331 3.295-2.604 0-4.731-2.16-4.731-4.823 0-2.662 2.122-4.822 4.728-4.822 1.485 0 2.479.633 3.045 1.178l2.073-1.994c-1.33-1.245-3.056-1.995-5.115-1.995C3.412 4.365 0 7.785 0 12s3.414 7.635 7.635 7.635c4.41 0 7.332-3.098 7.332-7.461a6.96 6.96 0 0 0-.12-1.265H7.635zm16.365 0h-2.183V8.726h-2.183v2.183h-2.182v2.181h2.184v2.184h2.189V13.09H24'/%3E%3C/svg%3E"), "#", "%23");
}

.e-footer-copyright {
    font-size: $font-size-sm;
    padding-top: $spacer / 2;
    padding-bottom: $spacer / 2;
    color: $footer-copyright-color;
    background: $footer-copyright-bg;
    p {
        margin: 0;
        color: $footer-copyright-color;
    }
    a {
        color: $footer-copyright-link-color;
        &:hover {
            color: $footer-copyright-link-hover-color;
        }
    }
    .e-copyright {}
    .website-by-co3 {}
}
