.btn {
    border-radius: 0.5em;
    font-weight: $font-weight-bold;
    padding: 0.75em 1.55em;
    transition: $fade-speed all ease-in-out;
    text-transform: none;
    font-size: $font-size-sm;
    color: $gray-100;
    .btn-primary {
        &:hover {
            border: darken(theme-color("primary"), 10%);
        }
    }
}

@include media-breakpoint-up(lg) {
    .e-nav-cta {
        .nav {
            .nav-item .nav-link {
                border-radius: 0.5em;
                color: $white;
                &:hover {
                    background: darken(theme-color("primary"), 10%);
                }
            }
            .nav-item:last-child {
                .nav-link.btn {
                    background: #fff;
                    border: 1px solid theme-color("primary");
                    color: theme-color("primary");
                    &:hover {
                        background: theme-color("primary");
                        color: $gray-100;
                    }
                }
            }
        }
    }
}