.p-banner-center-middle .p-img-container:after {
	background: rgba(0,0,0,.25);
}

@keyframes zoom {
    from {
        transform: scale(1, 1);
    }
    to {
        transform: scale(1.2, 1.2);
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.e-banner-zoom {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transform: translateZ(0);
    .e-banner-zoom-image {
        @include media-breakpoint-down($nav-stacking-breakpoint) {
            object-fit: cover;
            height: 100%;
        }
        will-change: transform;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        opacity: 0;
        display: block;
        transition: opacity 1s;
        &.e-banner-zoom-image-animate-in {
            opacity: 1;
            animation: zoom 10s forwards ease-out -.1s;
        }
        &.e-banner-zoom-image-animate-out {
            opacity: 0;
            transform: scale(1.2, 1.2);
        }
    }
}

.e-banner-teaser {
    color: white;
    font-size: 3em;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    .e-banner-teaser-icon {
        animation: bounce 2s infinite;
        color: white;
    }
    &:hover {
        color: white;
        .e-banner-teaser-icon {
            animation: none;
        }
    }
}
