.e-canvas {
    position: relative;
    max-width: $canvas-max-width;
    margin-right: auto;
    margin-left: auto;
    z-index: 1;
    @include media-breakpoint-down($nav-hamburger-breakpoint) {
        padding-top: $header-height-small;
    }
    @include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
        padding-top: $header-height-large;
    }
    &.is-fullscreen {
        max-width: 100%
    }
}
