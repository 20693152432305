.material-icons {
    font-size: 1em;
    vertical-align: -10%;
}

// TODO_ Lav flere modifier klasser til 1.25 og 1.5 em sizes
.material-icons-large {
    font-size: 1.75em;
    vertical-align: -27%;
}

.material-icons-2x {
    font-size: 2em;
    vertical-align: -25%;
}

.material-icons-fixed {
    width: 1.28571429em;
    text-align: center;
}

.material-icons {
    &.ml-1 {
        margin-left: ($spacer / 4) !important;
    }
    &.mr-1 {
        margin-right: ($spacer / 4) !important;
    }
    // &.ml-2 {
    //     margin-left: ($spacer / 4) !important;
    // }
    // &.mr-2 {
    //     margin-right: ($spacer / 4) !important;
    // }
}

.material-icons-ul {
    padding-left: 0;
    margin-left: 0;
    list-style-type: none;
    li {
        position: relative;
        padding-left: $spacer * 1.5;
        // line-height: $line-height-sm;
        .material-icons {
            position: absolute;
            left: 0;
            text-align: left;
            transform: scale(1.25) translateY(0.25em);
        }
    }
}
