@include media-breakpoint-down($nav-hamburger-breakpoint) {
    // All size and flexbox props resides in _e-header.scss
    .e-header-hamburger {
        .e-nav-cta {
            display: flex;
            .nav {
                flex: 1 1 auto;
                display: flex;
            }
            .nav-item {
                flex: 1 1 auto;
                display: flex;
                border-left: $border-width solid $header-border-color;
            }
            .nav-link {
                display: flex;
                align-items: center;
                flex: 1 1 auto;
                color: inherit;
            }
            .material-icons {
                // color: theme-color("primary");
                transform: scale(1.5);
                margin-right: $spacer * 0.25;
            }
        }
    }
    .e-nav {
        .e-nav-cta {
            display: flex;
            align-items: center;
            .nav {
                display: flex;
                flex-direction: row-reverse;
            }
            .nav-item {}
            .nav-link {
                border: $input-btn-border-width solid transparent;
                @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height, $btn-border-radius-sm);
                @include transition($btn-transition);
            }
            .nav-item:first-child {
                .nav-link {
                    @include button-variant(theme-color("primary"), theme-color("primary"));
                }
            }
            .nav-item + .nav-item {
                margin-right: $spacer / 2;
                .nav-link {
                    @include button-outline-variant(theme-color("primary"), color-yiq(theme-color("primary")));
                }
            }
            .material-icons {
                // color: theme-color("primary");
                transform: scale(1.5);
                margin-right: $spacer * 0.25;
            }
        }
    }
}

@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    // All size and flexbox props resides in _e-header.scss
    .e-nav-cta {
        display: flex;
        align-items: center;
        .nav {
            display: flex;
            flex-direction: row-reverse;
        }
        .nav-item {}
        .nav-link {
            border: $input-btn-border-width solid transparent;
            @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height, $btn-border-radius-sm);
            @include transition($btn-transition);
        }
        .nav-item:first-child {
            .nav-link {
                @include button-variant(theme-color("primary"), theme-color("primary"));
            }
        }
        .nav-item+.nav-item {
            margin-right: $spacer / 2;
            .nav-link {
                @include button-outline-variant(theme-color("primary"), color-yiq(theme-color("primary")));
            }
        }
        .material-icons {
            // color: theme-color("primary");
            transform: scale(1.5);
            margin-right: $spacer * 0.25;
        }
    }
}
