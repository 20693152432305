.p-img-container-parallax {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    .p-img-parallax {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        opacity: 0;
        display: block;
        @include media-breakpoint-down(xs) {
            max-width: none;
            width: 200%;
            left: -50%;
        }
        &.is-loaded {
            transition: opacity $transition-fade-speed * 2 $transition-fade-easing;
            opacity: 1;
            will-change: transform;
        }
    }
}
