.p-tile {
    border: 0.15em solid #fff;
    .p-txt-container {
        h4 {
            color: hsla(0, 0%, 100%, .9);
            font-style: italic;
            font-weight: $font-weight-normal;
            border-bottom: .15em solid theme-color("primary");
            font-size: 1.50em;
            text-transform: none;
            font-family: $font-family-serif;
            margin-bottom: 1em;
        }
        a {
            text-decoration:none;
        }
    }
}

@include media-breakpoint-up(lg) {
    .p-tile-img {
        .p-txt-container {
            .p-txt-wrapper {
                transition: $fade-speed all ease-in-out;
                opacity: 0;
            }
        }
        .p-img-container {
            .p-img {
                transition: transform 10s;
                &::after {
                    display: none;
                }
            }
        }
        &:hover {
            .p-img-container {
                .p-img {
                    &::after {
                        display: block;
                    }
                }
            }
            .p-txt-wrapper {
                opacity: 1;
            }
        }
    }
}

.p-tile-text-no-animate {
    .p-img-container {
        .p-img {
            &::after{
                display:block;
            }
        }
    }
    .p-txt-container {
        .p-txt-wrapper {
            opacity: 1;
        }
    }
}