.e-reveal-conceal {
    opacity: 0;
    transform: translateY(10vh);
    will-change: transform, opacity;
}

.e-reveal-fade {
    opacity: 1;
    transform: none;
    transition: opacity $transition-fade-speed * 2 $transition-fade-easing;
}

.e-reveal-slide {
    opacity: 1;
    transform: none;
    transition: transform $transition-slide-speed * 2 $transition-slide-easing, opacity $transition-fade-speed $transition-fade-easing;
}
// https://meyerweb.com/eric/thoughts/2011/09/12/un-fixing-fixed-elements-with-css-transforms/
.reset-transform-containing-block {
    opacity: 1;
    transform: none;
    transition: none;
    will-change: initial;
}
