.e-checkout-page {
    .is-hidden {
        display: none;
    }
    .e-checkout {
        min-height: 100vh;
        .e-checkout-step-loading-overlay {
            background: rgba($loading-bg, .85);
        }
    }
    .e-checkout-step {
        padding: 1em;
        margin-top: $border-width * -1;
        margin-bottom: $spacer / 2;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        box-shadow: none;
        &.e-checkout-step-active {
            .e-checkout-step-header {
                color: inherit;
                border-bottom: 1px solid $card-border-color;
            }
            .e-checkout-step-header-badges-index {
                background: theme-color("primary");
            }
        }
        &.e-checkout-step-active {
            .e-checkout-step-header {
                color: inherit;
            }
            .e-checkout-step-header-badges-index {
                background: theme-color("primary");
            }
        }
        &.e-checkout-step-completed {
            .e-checkout-step-header-badges-completed {
                background: theme-color("success");
            }
            .e-checkout-step-summary {
                max-height: 100vh;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        &.e-checkout-step-has-changed {
            .e-checkout-step-header-badges-warning {
                background: theme-color("danger");
            }
        }
        .e-checkout-step-card-block {
            padding: 1em;
            @include media-breakpoint-down(md) {
                padding: 0 !important;
            }
        }
        .e-checkout-next-step {
            @include media-breakpoint-down(md) {
                float: right;
                width: auto;
                font-size: $font-size-sm !important;
            }
        }
        .e-checkout-step-header {
            border-bottom-color: transparent;
        }
        .e-checkout-step-header-badges {
            span {
                display: inline-block;
                width: 35px;
                height: 35px;
                margin-right: .5em;
                color: #fff;
                text-align: center;
                background: $gray-500;
                border-radius: 100%;
                &.e-checkout-step-header-badges-warning {
                    display: none;
                }
            }
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        .e-checkout-step-header {
            padding: 1em;
            font-size: $font-size-lg;
            color: $gray-500;
            background: transparent;
            transition: all .25s $transition-collapse-easing;
        }
        .e-checkout-step-list-group {
            .list-group-item {
                display: flex;
                justify-content: space-between;
                .e-checkout-list-icon {
                    margin-left: auto;
                    display: inline-block;
                    img {
                        position: relative;
                        max-width: 100%;
                        height: 100%;
                        min-height: 25px;
                        max-height: 100%;
                    }
                }
            }
        }
        .list-group {
            .list-group-item {
                cursor: pointer;
                transition: background .25s ease;
                &:first-child {
                    @include border-top-radius($list-group-border-radius * 2);
                }
                &:last-child {
                    margin-bottom: 0;
                    @include border-bottom-radius($list-group-border-radius * 2);
                }
                &:hover {
                    background: $gray-100;
                }
            }
            .form-check {
                min-height: 1em;
                width: 100%;
                margin: 0;
                .form-check-label {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
            }
            .form-check-input {
                margin-top: 0;
            }
        }
        .e-checkout-step-summary {
            font-size: 0.75em;
            max-height: 0;
            transition: max-height $transition-collapse-speed $transition-collapse-easing;
            overflow: hidden;
            color: $gray-400;
        }
        @include media-breakpoint-down(md) {
            box-shadow: none;
            display: none;
            padding: 0;
            border: 0;
            &.e-checkout-step-active {
                display: block;
                .e-checkout-step-header {
                    background: transparent;
                    border: 0;
                    padding: 0;
                }
            }
        }
    }
    .e-checkout-summery-icon {
        img {
            height: 25px;
        }
    }
    .e-checkout-progress {
        ul {
            display: flex;
            justify-content: space-between;
            list-style: none;
            li {
                display: flex;
                flex-direction: column;
                position: relative;
                justify-content: center;
                align-items: center;
                font-size: $font-size-sm;
                color: $gray-400;
                &::before {
                    display: flex;
                    justify-content: center;
                    content: attr(data-index);
                    background: $gray-100;
                    border-radius: 50%;
                    width: 2rem;
                    height: 2rem;
                    padding: .2rem 0;
                    text-align: center;
                    border: 2px solid $gray-100;
                }
                &.e-checkout-progress-completed {
                    color: theme-color("success");
                    cursor: pointer;
                    &::before {
                        @include material-icons("done");
                        transition: transform $transition-slide-speed / 2 $transition-slide-easing;
                        text-decoration: none;
                        background: theme-color("success");
                        color: #fff;
                        border-color: theme-color("success");
                    }
                }
                &.e-checkout-progress-active {
                    color: $gray-900;
                    &::before {
                        font-weight: $font-weight-bold;
                        background: $gray-100;
                        color: $gray-900;
                        border-color: theme-color("success");
                    }
                }
            }
        }
    }
    .e-checkout-form {
        .checkbox-toggle {
            float: right;
            padding: .5em;
            //font-size: .7em;
            margin: .3em 0 -3em;
            input {
                margin-top: .35em;
            }
        }
        .inline-form-button {
            align-self: flex-end;
        }
        .form-group.is-invalid {
            label {
                color: inherit;
            }
        }
        .form-check-input {
            margin-top: 0.35em;
        }
    }
    .e-checkout-cart {
        background: $body-bg;
        position: sticky;
        top: 5em;
    }
    @include media-breakpoint-down(md) {
        .collapsing {
            transition: none;
        }
    }
    .e-checkout-actions {
        display: flex;
        justify-content: space-between;
        margin: $btn-block-spacing-y 0;
        &.e-checkout-actions-align-center {
            align-items: center;
        }
        .e-checkout-next-step {
            margin-left: auto;
        }
    }
    &.e-checkout-page-approve {
        .e-checkout .e-checkout-step .e-checkout-step-summary {
            color: $gray-900;
        }
        .e-checkout-step .e-checkout-step-header {
            color: inherit;
        }
    }
}
