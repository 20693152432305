@include media-breakpoint-up(lg) {
    .e-nav-global .nav>.nav-item>.nav-link {
        padding: 2.2em 0.75em;
        text-transform: initial;
        font-size: $font-size-sm;
        &:before {
            top: auto;
            bottom: 0;
            background: theme-color("primary");
        }
        &:hover {
            color: theme-color("primary");
        }
    }
}