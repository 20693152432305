.carousel-control-prev,
.carousel-control-next { 
	opacity: .4;
	.carousel:not(.image-gallery):hover & {
        opacity: .6;
    }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	background-color: $white;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    margin-right: .3em;
    margin-left: .3em;
}
.p-carousel {
  &.no-caption-content {
    .carousel-caption {
      display: none;
    }
    .carousel-inner {
      padding-bottom: 0;
    }
    .carousel-control-prev, .carousel-control-next{
      padding-bottom: 0;
    }
  }
}