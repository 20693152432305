@include media-breakpoint-down($nav-hamburger-breakpoint) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-utilities-secondary {
        .e-nav & {
            font-size: $font-size-sm;
            // font-family: $font-family-monospace;
            margin-bottom: 3em;
            border-top: $border-width solid $header-border-color;
            border-bottom: $border-width solid $header-border-color;
            background: rgba($header-color, 0.05);
            opacity: 0;
            //Props for item animation entry in active modal.
            .show & {
                transition: all $transition-fade-speed $transition-fade-easing 0.125s;
                opacity: 1;
            }
            // One level only.
            > .nav {
                margin: 0 ( $grid-gutter-width / 2);
                padding: 0 $canvas-padding-sm;
                > .nav-item {
                    > .nav-link {
                        display: block;
                        padding: 0.75em 0.125em;
                        color: rgba($header-color, 0.85);
                        background: none;
                        white-space: normal;
                        text-transform: uppercase;
                        //font-weight: $font-weight-bold;
                    }
                }
            }
        }
        .e-footer & {
            .nav {
                margin-left: $nav-link-padding-x * -1;
            }
        }
    }
}

@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss and _e-footer.scss
    .e-nav-utilities-secondary {
        .nav {
            margin-left: $nav-link-padding-x * -1;
        }
        .nav-item {}
        .nav-link {}
    }
}
