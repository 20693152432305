.e-header-btn {
    display: flex;
    align-items: center;
    .e-header.is-transparent & {
        border: 0;
    }
}

@include media-breakpoint-down($nav-hamburger-breakpoint) {
    .e-header-btn {
        .e-header-hamburger & {
            border-left: $border-width solid $header-border-color;
        }
        .e-nav & {
            display: none;
            &.e-nav-toggle {
                display: flex;
            }
        }
        .nav-link {
            padding: $spacer / 2 $spacer;
            color: inherit;
        }
        .material-icons {
            transform: scale(1.5);
        }
    }
}

//Header styling for larger breakpoints.
@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    .e-header-btn {
        .nav-link {
            color: inherit;
            padding: $spacer / 2;
        }
        .material-icons {
            transform: scale(1.5);
        }
    }
}
