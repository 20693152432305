@include media-breakpoint-up(lg) {
    .e-nav-global .e-megamenu {
        .e-megamenu-content {
            flex: 0 0 100%;
            max-width: 100%;
            margin-left: 0;
            .row {
                justify-content: center;
            }
            .btn.btn-primary {
                color: $white;
                text-decoration: none;
                font-size: 1em;
                &:hover {
                    background: darken(theme-color("primary"), 10%);
                }
            }
            a {
                &.arrow-right {
                    text-decoration: none;
                }
            }
            .btn {
                text-decoration: none;
                color: $gray-100;
                font-size: $font-size-base;
                &:hover {
                    color: rgba(0, 0, 0, 0.85);
                }
            }
        }
    }
    .e-megamenu-nav {
        display: none;
    }
    .e-nav-global .e-megamenu .e-megamenu-item {
        display: none;
        &:last-child {
            display: block;
            flex: 0 0 100%;
            text-align: center;
            margin: 0 auto;
        }
    }
    .e-nav-global .dropdown.show>.collapse,
    .e-nav-global .e-dropdown-mega.show .collapse {
        border-top: 1px solid $gray-300;
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 10px 2px rgba(0, 0, 0, 0), 0 5px 5px -3px rgba(0, 0, 0, .1);
    }
}