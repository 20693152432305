.e-breadcrumb {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.e-breadcrumb-light-shadow {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, transparent 95%);
    &::after {
        content: "";
        display: block;
        height: $spacer * 1.5;
        pointer-events: none;
    }
}

.breadcrumb {
    font-size: 0.75em;
    padding: $breadcrumb-padding-y $breadcrumb-padding-x;
}

.breadcrumb-item {
    color: $breadcrumb-color;
    + .breadcrumb-item::before {
        color: $breadcrumb-divider-color;
        content: "#{$breadcrumb-divider}";
    }
    a {
        padding: $breadcrumb-item-padding;
        color: inherit;
        text-decoration: underline;
    }
    &.is-active {
        color: $breadcrumb-active-color;
        a {
            text-decoration: none;
        }
    }
    .e-breadcrumb-light &,
    .e-breadcrumb-light-shadow & {
        color: e-section-color("dark", "text");
        &.is-active {
            color: e-section-color("dark", "headings");
        }
    }
    .e-breadcrumb-dark & {
        color: e-section-color("light", "text");
        &.is-active {
            color: e-section-color("light", "headings");
        }
    }
}
