.e-cookie {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed + 1;
    font-size: $font-size-sm;
    background: rgba(0, 0, 0, .85);
    color: #fff;
    a {
        color: inherit;
        text-decoration: underline;
    }
    &.is-accepted {
        display: none;
    }
}

.e-cookie-accept-btn {
    &,
    &:hover {
        color: currentColor;
        background-image: none;
        background-color: transparent;
        border-color: currentColor;
    }
}
