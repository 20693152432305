@include media-breakpoint-down($nav-hamburger-breakpoint) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-context {
        font-size: 1.125em;
        background: e-section-color("light", "bg");
        .nav {
            z-index: 1;
            position: sticky;
            top: $header-logo-height-small + ($header-logo-spacing-small * 2);
            padding: ($spacer / 2) 0;
            display: flex;
            flex-direction: column;
        }
        .nav-item {
            opacity: 0;
            transition: opacity $transition-fade-speed $transition-fade-easing;
            $item-entry-delay: 0.125s;
            @for $item from 1 through 10 {
                &:nth-child(#{$item}) {
                    transition-delay: $item-entry-delay * $item;
                }
            }
        }
        .nav-link {
            color: $headings-color;
            padding: ($spacer * 0.625) ($spacer * 1.25);
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        .e-nav-pageicon {
            font-size: $spacer * 1;
            margin-bottom: $spacer * 0.125;
            color: theme-color("primary");
        }
        .e-nav-pagename {
            font-size: $font-size-sm;
            line-height: $line-height-sm;
            // text-transform: uppercase;
        }
    }
    //Props for item animation entry in active modal.
    .e-nav-context {
        .show & {
            // First level only.
            > .nav {
                > .nav-item {
                    opacity: 1;
                }
            }
        }
    }
}

@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-context {
        @include e-canvas-padding;
        position: absolute;
        right: ($grid-gutter-width / 2);
        bottom: 0;
        display: flex;
        align-items: center;
        z-index: 1;
        .nav {
            display: flex;
        }
        .nav-item {}
        .nav-link {
            padding: ($spacer * 0.675) ($spacer * 0.5);
            color: $headings-color;
            line-height: $line-height-sm;
            white-space: nowrap;
        }
        .e-nav-pageicon {
            color: theme-color("primary");
            transform: scale(1.25) translateY(0.0675em);
            margin-right: $spacer * 0.375;
        }
    }
}
