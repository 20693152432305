.e-nav-local-horizontal .nav-link.is-active,
.e-nav-local-horizontal .nav-link.is-inpath {
    color: theme-color("primary");
    font-weight: inherit;
}

.nav-item {
    .is-active {
        color: theme-color("primary");
        &.nav-item-header {
            font-weight: $font-weight-bold;
        }
    }
    .nav-item-header {
        font-weight: $font-weight-bold;
    }
}

.e-nav-local-horizontal {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-color: $gray-100;
    .e-nav-local-heading {
        margin-right: 0;
    }
    &.is-sticky {
        background-color: $gray-100;
    }
}