@include media-breakpoint-up(breakpoint-next($nav-stacking-breakpoint)) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-utilities-primary {
        height: 3em;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            background: $gray-300;
        }
        >.nav {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            >.nav-item {
                >.nav-link {
                    font-size: 11px;
                    text-align: right;
                    padding-left: 15px;
                    padding-right: 15px;
                    text-transform: none; //align-items: flex-end;
                    &.is-inpath,
                    &.is-active {
                        color: $header-color;
                    }
                    &:hover {
                        &::before {
                            background: rgba($header-color, 1);
                        }
                    }
                    &.is-inpath,
                    &.is-active {
                        &::before {
                            background: rgba($header-color, 1);
                        }
                    }
                }
                &:last-child {
                    >.nav-link {
                        padding-right: 0;
                    }
                }
            }
        }
        .nav-item.dropdown.open {
            position: relative;
            &::after {
                //display: none;
                content: "";
                position: absolute;
                bottom: -6px;
                left: 50%;
                z-index: $zindex-dropdown + 1;
                transform: translate(-50%, 0);
                border-top: 0;
                border-right: $caret-width * 2 solid rgba(0, 0, 0, 0);
                border-bottom: $caret-width * 2 solid $dropdown-bg;
                border-left: $caret-width * 2 solid rgba(0, 0, 0, 0);
                margin: 0;
            }
        }
        .dropdown {
            .dropdown-menu {
                .nav {
                    flex-direction: column;
                }
            }
        }
        .dropdown-toggle {
            &:after {
                display: none;
            }
        }
        .nav-link[data-toggle="dropdown"] {
            cursor: pointer;
        }
        .dropdown-item:focus,
        .dropdown-item:hover {
            background: none;
        }
        .dropdown-item.is-active {
            background: none;
        }
        .dropdown-menu {
            border: 0;
        }
        .dropdown-menu .dropdown-item .nav-link {
            display: block;
            color: $body-color;
            font-weight: $font-weight-base;
            padding-left: 1.5em;
            padding-top: .25em;
            padding-bottom: .25em;
            position: relative;
            text-align: left;
            &::after {
                color: $link-color;
                content: 'arrow_forward';
                font-family: 'Material Icons';
                font-weight: normal;
                font-style: normal;
                font-size: 1em;
                vertical-align: -10%;
                letter-spacing: normal;
                text-transform: none;
                display: inline-block;
                white-space: nowrap;
                word-wrap: normal;
                direction: ltr;
                font-feature-settings: 'liga';
                font-smoothing: antialiased;
                width: 0;
                opacity: 0;
                transform: translateX(-1em);
            }
            &:hover {
                color: $link-color;
                &::after {
                    opacity: 1;
                    transform: translateX(0.5em);
                    transition: opacity $fade-speed / 2 $fade-easing, transform $slide-speed / 2 $slide-easing;
                }
            }
        }
    }
    .e-nav-global {
        .dropdown.show {
            .dropdown-toggle::after {
                display: none;
            }
        }
    }
}

/*@include media-breakpoint-down($nav-stacking-breakpoint) {
    //Overrides to fix collapse vs. dropdown conflicts.
    .e-nav-utilities-primary {
        .collapse {
            display: none;
        }
        .collapse.in {
            display: block;
        }
        .dropdown-toggle::after {
            display: none;
        }
        .dropdown-menu {
            position: static;
            float: none;
            min-width: 0;
            border: 0;
            box-shadow: none;
            background: none;
            padding: 0;
            font-size: inherit;
        }
    }

    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-utilities-primary {
        // All levels
        .nav {}
        .nav-item {}
        .nav-link {
            display: block;
            background: none;
            text-align: left;
            font-weight: $font-weight-normal;
        }
        // First level only
        >.nav {
            flex-direction: column;
            background: rgba(#fff, 0.1);
            border-top: 1px solid rgba(#fff, 0.2);
            border-bottom: 1px solid rgba(#fff, 0.2);
            padding-left: 5%;
            padding-right: 5%;
            >.nav-item {
                border-bottom: 1px solid rgba(#fff, 0.2);
                padding: 0;
                margin: 0;
                &:last-child {
                    border-bottom: 0;
                }
                > .nav-link {
                    padding-top: 0.8em;
                    padding-bottom: 0.8em;
                    &[data-toggle="dropdown"] {
                        cursor: pointer;
                    }
                }
                &.language{
                    a::after{
                        content: attr(title);
                        font-size: $font-size-sm;
                        display: inline-block;
                        padding: 0 1em;
                    }
                }
            }
            // All sub levels
            .nav {
                margin: 0 0 0.8em 2em;
                .nav-item {}
                .nav-link {}
            }
        }
        .dropdown-menu {
            .dropdown-item {
                background: none;
                .nav-link {}
            }
        }
    }
}
*/