.e-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
    color: $header-color;
    background: $header-bg;
    transform: translateY(0);
    transition: transform $transition-slide-speed $transition-slide-easing;
    will-change: transform;
    &.is-sticky {
        position: fixed;
        &.is-stuck {
            box-shadow: $shadow-md;
        }
    }
    &.is-hidden {
        transform: translateY(-110%);
    }
    &.is-transparent {
        color: rgba( 255, 255, 255, 1);
        background: none;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: -50%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 95%);
            pointer-events: none;
        }
        &.is-stuck {
            box-shadow: none;
        }
    }
}

.e-header-container {
    @include e-canvas-padding;
    max-width: $canvas-max-width;
    margin: 0 auto;
}

@include media-breakpoint-down($nav-hamburger-breakpoint) {
    .e-header-hamburger {
        font-size: $font-size-sm;
        display: flex;
        .e-logo {
            // order: 1;
            margin-right: auto;
        }
        .e-nav-cta {
            // order: 2;
            flex: 0 1 auto;
        }
        .e-nav-toggle {
            // order: 3;
            margin-right: e-strip-unit($grid-gutter-width / 2) * -1vw - $canvas-padding-sm;
            .material-icons {
                margin-right: $spacer * 0.25;
            }
        }
        .nav-link {
            padding-left: e-strip-unit($grid-gutter-width / 2) * 1vw;
            padding-right: e-strip-unit($grid-gutter-width / 2) * 1vw;
        }
        // Pseudo element to force wrap flex items.
        // &::after {
        //     content: "";
        //     flex: 0 0 100%;
        //     order: 3;
        // }
    }
    .e-nav {
        height: 100vh;
        color: $header-color;
        background: $header-bg;
        transform: translate3d(-101%, 0, 0);
        will-change: transform;
        transition: transform $transition-slide-speed $transition-slide-easing;
        &.show {
            transform: translate3d(0, 0, 0);
            transition: transform ( $transition-slide-speed / 1.5) $transition-slide-easing;
            .e-nav-toggle {
                transform: rotate(360deg);
                transition: transform .25s linear .125s;
            }
        }
        .e-nav-container {
            color: $header-color;
            display: flex;
            flex-wrap: wrap;
            // Size and flexbox props for all nav containers.
            .e-logo {
                order: 1;
                margin-right: auto;
                z-index: 2;
            }
            .e-nav-global {
                flex: 1 1 75%;
                width: 75%;
                order: 4;
            }
            .e-nav-cta {
                display: none;
            }
            .e-nav-utilities-primary {
                order: 6;
                flex: 0 0 100%;
                z-index: 0;
            }
            .e-nav-utilities-secondary {
                display: none;
            }
            .e-nav-context {
                // https://github.com/philipwalton/flexbugs#flexbug-11
                order: 5;
                flex: 0 1 25%;
                width: 25%;
                min-height: 100vh;
                z-index: 1;
            }
            .e-nav-toggle {
                order: 2;
                position: sticky;
                top: 0;
                z-index: 3;
            }
            &::before {
                content: "";
                order: 3;
                flex: 0 0 100%;
            }
            // &::after {
            //     content: "";
            //     order: 7;
            //     flex: 0 0 100%;
            //     // border-top: 1px solid $header-border-color;
            // }
        }
    }
}

//Header styling for larger breakpoints.
@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    .e-header-hamburger {
        display: none;
    }
    .e-header {}
    .e-header-container {
        >.container-fluid {
            >.row {
                >.col-12 {
                    position: static;
                }
            }
        }
    }
    .e-nav {
        display: block;
        position: static;
        overflow: visible;
        opacity: 1;
        .e-nav-container {
            color: $header-color;
            display: flex;
            flex-wrap: wrap;
        }
        //Size and flexbox props for all nav containers.
        .e-logo {
            order: 1;
            // flex: 0 1 auto;
            margin-right: auto;
        }
        .e-nav-global {
            order: 2;
            // flex: 1 1 auto;
            margin-left: auto;
        }
        .e-nav-cta {
            order: 10;
            // flex: 0 1 auto;
            margin-left: $spacer;
        }
        .e-nav-context {
            display: none;
            // order: 4;
            // flex: 0 1 auto;
        }
        .e-nav-utilities-primary {
            order: 0;
            flex: 0 1 100%;
        }
        .e-nav-utilities-secondary {
            .e-header & {
                display: none;
            }
        }
        .e-search-toggle {
            order: 3;
            margin-left: $spacer / 2;
        }
        .e-cart-mini {
            order: 4;
        }
        .e-nav-toggle {
            display: none;
        }
        // Pseudo element to force wrap flex items.
        // &::after {
        //     content: "";
        //     order: 6;
        //     flex: 0 0 100%;
        // }
    }
}
