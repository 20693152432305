.e-cart-mini {
    .nav-link {
        position: relative;
    }
    .material-icons {
        z-index: 1;
    }
    .badge {
        position: absolute;
        z-index: 2;
        top: 50%;
        right: 50%;
        transform: translate(125%, -125%);
    }
}

