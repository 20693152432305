.e-product {
    .e-product-container {
        font-size: 0.75em;
        position: relative;
        z-index: 2;
        padding: $spacer;
    }
    .e-product-text-container {
        font-size: $font-size-sm;
        margin-bottom: $spacer;
    }
    .e-product-name {}
    .e-product-text {}
    .e-product-price {
        font-size: 1.25em;
    }
    .e-product-price-before {
        text-decoration: line-through;
    }
    .e-product-form {
        position: relative;
        .e-loading-overlay {
            background: transparent; //theme-color("primary");
        }
        .e-loading-spinner {
            left: 10%;
            background: currentColor;
            background: linear-gradient(60deg, currentColor 20%, rgba(255, 255, 255, 0) 60%);
        }
        .e-loading-spinner::before {
            background: currentColor;
        }
        .e-loading-spinner::after {
            background: theme-color("primary");
        }
    }
    .e-product-image-container {
        position: relative;
        padding-top: percentage( 80 / 100);
        margin-bottom: $spacer;
        .e-product-image-wrapper {}
        .carousel {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            height: auto;
            @include media-breakpoint-down(sm) {
                margin-bottom: $spacer;
            }
        }
        .carousel-item {
            .e-product-image {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: zoom-in;
            }
        }
        .carousel-indicators {
            @include media-breakpoint-down(sm) {
                bottom: $spacer * -1;
                height: $spacer;
                margin: 0;
                justify-content: flex-start;
                li {
                    font-size: $font-size-sm;
                    flex: 0 0 auto;
                    border: $border-width solid $border-color;
                    background: $body-color;
                    opacity: $text-muted;
                    &.active {
                        border-color: $link-color;
                        background: rgba(0, 0, 0, 0);
                        opacity: 1;
                    }
                }
            }
            @include media-breakpoint-up(md) {
                font-size: $carousel-indicator-width * 3;
                position: static;
                margin: 0;
                flex-flow: column;
                justify-content: center;
                order: -1;
                li {
                    flex: 0 0 auto;
                    position: relative;
                    margin: 0 ($spacer / 8) ($spacer / 8) 0;
                    border: $border-width solid $border-color;
                    border-radius: $border-width;
                    background-color: rgba(0, 0, 0, 0);
                    &.active {
                        border-color: $link-color;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    span {
                        position: absolute;
                        top: 0.0625em;
                        right: 0.0625em;
                        bottom: 0.0625em;
                        left: 0.0625em;
                        background-color: rgba(0, 0, 0, 0);
                        background-size: contain;
                        background-position: 50% 50%;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
        .e-product-image-modal-link {
            @include media-breakpoint-up(md) {
                display: none;
            }
            position: absolute;
            right: 0;
            bottom: $spacer * -1;
            z-index: 16;
            .material-icons {
                font-size: 1.375em;
                vertical-align: -26%;
                padding-right: 0.25em;
                color: $body-color;
            }
        }
    }
    .e-product-image-modal {
        &.modal-fullscreen {
            overflow-y: hidden;
            .modal-content {
                min-height: 0;
            }
        }
        .modal-dialog,
        .modal-content,
        .modal-body,
        .carousel {
            height: 100%;
        }
        .modal-body {
            padding: 0;
        }
        .carousel-item {
            background-size: contain;
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }
        .carousel-control {
            &.left {
                left: 0.5em;
            }
            &.right {
                right: 0.5em;
            }
        }
        .carousel-indicators {
            li {
                //border-color: $border-color;
                background-color: $body-color;
                opacity: $text-muted;
                @include media-breakpoint-up(md) {
                    background: $white;
                    opacity: 1;
                    font-size: 3em;
                    margin: $spacer / 8;
                    border-radius: 0;
                    border: $border-width solid $border-color;
                }
                &.active {
                    border-color: $link-color;
                    background: $white;
                    opacity: 1;
                }
                span {
                    display: none;
                    @include media-breakpoint-up(md) {
                        display: block;
                    }
                    position: absolute;
                    top: 0.0625em;
                    right: 0.0625em;
                    bottom: 0.0625em;
                    left: 0.0625em;
                    background-size: contain;
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                }
            }
        }
    }
    .e-product-section {
        padding: $spacer 0;
        border-top: $border-width solid $border-color;
    }
    .e-product-section-header {
        padding-bottom: $spacer;
        &[data-toggle="collapse"] {
            cursor: pointer;
        }
    }
}
