.e-footer {
	border-top: 1px solid $gray-300;
    font-size: $font-size-base;
}

.e-footer-content {
    padding-top: 5.5em;
    padding-bottom: 5.5em;
    p a:not(.btn){
    	text-decoration: none;
    	&:hover{
    		text-decoration: underline;
    	}
    }
}

.e-footer-copyright a {
    color: #fff;
}

.e-footer-content .form-control {
    background-color: #fff;
}