// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.


// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components

// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.


// stylelint-disable
$white:  #fff;
$gray-100: #f5f5f5;
$gray-200: #eee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
$black:  #000;

$grays: ();
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

$blue:    #2196f3; // 2196f3
$indigo:  #3f51b5;
$purple:  #9c27b0;
$pink:    #e91e63;
$red:     #f44336; // f44336
$orange:  #ff9800; // f9a825
$yellow:  #ffeb3b; // ffeb3b
$green:   #4caf50; // 4caf50
$teal:    #009688;
$cyan:    #00bcd4;
$olive-green: #545F4F;

$colors: ();
$colors: map-merge((
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": grays("500"),
  "black": $black,
  "olive-green": $olive-green
), $colors);

$theme-colors: ();
$theme-colors: map-merge((
  "primary": #fcbf29,
  "secondary": #acbeaf,
  "tertiary": #87a1af,
  "quaternary": #dbd5b9,
  "info": $blue,
  "warning": $yellow,
  "success": $green,
  "danger": $red,
  "light": $gray-100,
  "dark": $gray-800,
  "olive-green": $olive-green
), $theme-colors);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8%;


// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: rgba( 0, 0, 0, 0.65 );
$yiq-text-light: rgba( 255, 255, 255, .75 );
$yiq-headings-dark: rgba( 0, 0, 0, 0.85 );
$yiq-headings-light: rgba( 255, 255, 255, 1 );
$yiq-link-dark: theme-color("primary");
$yiq-link-light: rgba( 255, 255, 255, 1 );
$yiq-border-dark: rgba( 0, 0, 0, 0.15 );
$yiq-border-light: rgba( 255, 255, 255, 0.35 );

$shadow-xs:              0 2px 1px -1px rgba(0, 0, 0, .14), 0 1px 1px 0 rgba(0, 0, 0, .2), 0 1px 3px 0 rgba(0, 0, 0, .12);//.mdl-shadow--1dp
$shadow-sm:              0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);//.mdl-shadow--2dp
$shadow-md:              0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2);//.mdl-shadow--4dp
$shadow-lg:              0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);//.mdl-shadow--8dp
$shadow-xl:              0 7px 8px -4px rgba(0, 0, 0, .14), 0 12px 17px 2px rgba(0, 0, 0, .12), 0 5px 22px 4px rgba(0, 0, 0, .2);//.mdl-shadow--12dp

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-flex:               true;
$enable-rounded:            true;
$enable-shadows:            true;
$enable-gradients:          true;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer: 1.6em;
$spacers: (
  0: 0,
  1: ($spacer * .5),
  2: ($spacer * 1),
  3: ($spacer * 1.5),
  4: ($spacer * 2),
  5: ($spacer * 2.5),
  6: ($spacer * 3)
);

$border-width:               1px;
$border-color:               rgba(0,0,0,0.15);
$bg-color:                   $gray-100;

// Body
//
// Settings for the `<body>` element.
$body-color:                 rgba(0,0,0,.65);
$body-bg:                    $white;
$headings-color:             rgba(0,0,0,0.85);

// Links
//
// Style anchor elements.
$link-color:                 theme-color("primary");
$link-decoration:            none;
$link-hover-color:           $link-color;
$link-hover-decoration:      underline;

// Settings for the canvas element.
$canvas-border:              0;
$canvas-shadow:              none;

// Settings for header.
$header-color:                $gray-800;
$header-bg:                   #fff;
$header-border-color:         rgba($header-color, 0.15);
$header-logo-height-small:    2.75rem;
$header-logo-spacing-small:   0.75rem;
$header-logo-height-large:    2.75rem;
$header-logo-spacing-large:   1.25rem;
$header-height-small:         $header-logo-height-small + ($header-logo-spacing-small * 2);
$header-height-large:         $header-logo-height-large + ($header-logo-spacing-large * 2) + 2;

// Settings for content.
$content-color:               $body-color;
$content-bg:                  #fff;

// Settings for page loading overlay.
$loading-color:               theme-color("primary");
$loading-bg:                  $content-bg;

// Settings for sections and mosaic paragraphs.
$section-heights: ();
$section-heights: map-merge((
  "xs": 17.5vw,
  "sm": 25vw,
  "md": 37.5vw,
  "lg": 50vw,
  "xl": 62.5vw,
), $section-heights);
// Button color variants
$section-colors: ();
$section-colors: map-merge((
  "primary": (
    "bg": theme-color("primary"),
    "text": color-yiq(theme-color("primary")),
    "headings": color-yiq(theme-color("primary"), $yiq-headings-light, $yiq-headings-dark),
    "link": color-yiq(theme-color("primary"), $yiq-link-light, $yiq-link-dark),
    "border": color-yiq(theme-color("primary"), $yiq-border-light, $yiq-border-dark),
  ),
  "secondary": (
    "bg": theme-color("secondary"),
    "text": color-yiq(theme-color("secondary")),
    "headings": color-yiq(theme-color("secondary"), $yiq-headings-light, $yiq-headings-dark),
    "link": color-yiq(theme-color("secondary"), $yiq-link-light, $yiq-link-dark),
    "border": color-yiq(theme-color("secondary"), $yiq-border-light, $yiq-border-dark),
  ),
  "tertiary": (
    "bg": theme-color("tertiary"),
    "text": color-yiq(theme-color("tertiary")),
    "headings": color-yiq(theme-color("tertiary"), $yiq-headings-light, $yiq-headings-dark),
    "link": color-yiq(theme-color("tertiary"), $yiq-link-light, $yiq-link-dark),
    "border": color-yiq(theme-color("tertiary"), $yiq-border-light, $yiq-border-dark),
  ),
  "quaternary": (
    "bg": theme-color("quaternary"),
    "text": color-yiq(theme-color("quaternary")),
    "headings": color-yiq(theme-color("quaternary"), $yiq-headings-light, $yiq-headings-dark),
    "link": color-yiq(theme-color("quaternary"), $yiq-link-light, $yiq-link-dark),
    "border": color-yiq(theme-color("quaternary"), $yiq-border-light, $yiq-border-dark),
  ),
  "white": (
    "bg": $white,
    "text": color-yiq($white),
    "headings": color-yiq($white, $yiq-headings-light, $yiq-headings-dark),
    "link": color-yiq($white, $yiq-link-light, $yiq-link-dark),
    "border": color-yiq($white, $yiq-border-light, $yiq-border-dark),
  ),
  "light": (
    "bg": theme-color("light"),
    "text": color-yiq(theme-color("light")),
    "headings": color-yiq(theme-color("light"), $yiq-headings-light, $yiq-headings-dark),
    "link": color-yiq(theme-color("light"), $yiq-link-light, $yiq-link-dark),
    "border": color-yiq(theme-color("light"), $yiq-border-light, $yiq-border-dark),
  ),
  "dark": (
    "bg": theme-color("dark"),
    "text": color-yiq(theme-color("dark")),
    "headings": color-yiq(theme-color("dark"), $yiq-headings-light, $yiq-headings-dark),
    "link": color-yiq(theme-color("dark"), $yiq-link-light, $yiq-link-dark),
    "border": color-yiq(theme-color("dark"), $yiq-border-light, $yiq-border-dark),
  ),
  "black": (
    "bg": $black,
    "text": color-yiq($black),
    "headings": color-yiq($black, $yiq-headings-light, $yiq-headings-dark),
    "link": color-yiq($black, $yiq-link-light, $yiq-link-dark),
    "border": color-yiq($black, $yiq-border-light, $yiq-border-dark),
  ),
), $section-colors);

// Back to top link
$back-to-top-color:          $header-color;
$back-to-top-bg:             $gray-900;

// Settings for footer.
$footer-color:                          rgba(0, 0, 0, 0.65);
$footer-link-color:                     inherit;
$footer-headings-color:                 rgba(0, 0, 0, 0.85);
$footer-bg:                             #fff;
$footer-copyright-color:                rgba(255,255,255, 0.85);
$footer-copyright-link-color:           $footer-copyright-link-color;
$footer-copyright-link-hover-color:     rgba(255,255,255, 1);
$footer-copyright-bg:                   theme-color("secondary");

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// $grid-breakpoints: (
//   // Extra small screen / phone
//   xs: 0,
//   // Small screen / phone
//   sm: 544px,
//   // Medium screen / tablet
//   md: 768px,
//   // Large screen / desktop
//   lg: 992px,
//   // Extra large screen / wide desktop
//   xl: 1200px
// );

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 100%
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:               12;
$grid-gutter-width:          5%;

$nav-stacking-breakpoint:    md;

$canvas-max-width: 2560px;
$canvas-padding-sm: e-strip-unit( $spacer ) * 1vw;
$canvas-padding-lg: $canvas-padding-sm * 2;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:     'Muli', sans-serif;
$font-family-serif:          'Zilla Slab', serif;
$font-family-monospace:      $font-family-sans-serif;
$font-family-base:           $font-family-sans-serif;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root:             16px;

$font-size-base:             1em;
$font-size-lg:               1.25em;
$font-size-sm:               .875em;
$font-size-xs:               .75em;

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-base:            $font-weight-normal;

$line-height:                 strip-unit( $spacer );

$headings-margin-top:         1.5em;
$headings-margin-bottom:      0.75em;
$headings-font-family:        $font-family-sans-serif;
$headings-font-weight:        $font-weight-bold;
$headings-line-height:        1.25;

$font-size-h1:                2.75em;
$font-size-h2:                2em;
$font-size-h3:                1.25em;
$font-size-h4:                0.875em;

$display1-size:               6em;
$display2-size:               5.5em;
$display3-size:               4.5em;
$display4-size:               3.5em;

$display1-weight:             $font-weight-light;
$display2-weight:             $font-weight-light;
$display3-weight:             $font-weight-light;
$display4-weight:             $font-weight-light;

$font-weight-h1:              $font-weight-bold;
$font-weight-h2:              $font-weight-bold;
$font-weight-h3:              $font-weight-bold;
$font-weight-h4:              $font-weight-bold;

$font-family-h1:              $headings-font-family;
$font-family-h2:              $headings-font-family;
$font-family-h3:              $headings-font-family;
$font-family-h4:              $font-family-serif;

$lead-font-family:            $font-family-serif;
$lead-font-size:              1.375em;
$lead-font-weight:            $font-weight-normal;

$small-font-size:             0.875em;
$small-font-weight:           inherit;

$text-muted:                  0.65;

$abbr-border-color:           $border-color;

$blockquote-small-color:      $gray-400;
$blockquote-font-size:        1.25em;
$blockquote-border-color:     $border-color;

$hr-border-color:             $border-color;
$hr-border-width:             $border-width;

$dt-font-weight:              $font-weight-bold;

$nested-kbd-font-weight:      $font-weight-bold;

$list-inline-padding:         0.5em;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:         (4 / 3);
$line-height-sm:         1.5;

$border-radius:          0.125em;
$border-radius-lg:       $border-radius;
$border-radius-sm:       $border-radius;

$component-active-color: #fff;
$component-active-bg:    theme-color("primary");

$caret-width:            0.375em;
$caret-width-lg:         $caret-width;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-font-size:               0.875em;
$table-cell-padding:            0.5em;
$table-sm-cell-padding:         0.25em 0.125em;

$table-bg:                      transparent;
$table-bg-accent:               $gray-300;
$table-bg-hover:                $gray-400;
$table-bg-active:               $table-bg-hover;

$table-border-width:            $border-width;
$table-border-color:            $border-color;


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x:                  2.75em;
$btn-padding-y:                  1em;
$btn-font-family:                $font-family-monospace;
$btn-font-weight:                $font-weight-normal;
$btn-font-size:                  0.75em;
$btn-line-height:                1.25;
$btn-box-shadow:                 none;
$btn-active-box-shadow:          none;

$btn-primary-color:              #fff;
$btn-primary-bg:                 theme-color("primary");
$btn-primary-bg-hover:           darken(theme-color("primary"), 10%);
$btn-primary-border:             $btn-primary-bg;

$btn-secondary-color:            $body-color;
$btn-secondary-bg:               transparent;
$btn-secondary-bg-hover:         $btn-secondary-bg;
$btn-secondary-border:           $border-color;

$btn-info-color:                 #fff;
$btn-info-bg:                    theme-color("info");
$btn-info-border:                $btn-info-bg;

$btn-success-color:              #fff;
$btn-success-bg:                 theme-color("success");
$btn-success-border:             $btn-success-bg;

$btn-warning-color:              #fff;
$btn-warning-bg:                 theme-color("warning");
$btn-warning-border:             $btn-warning-bg;

$btn-danger-color:               #fff;
$btn-danger-bg:                  theme-color("danger");
$btn-danger-border:              $btn-danger-bg;

$btn-link-disabled-color:        $gray-400;

$btn-padding-x-sm:               $btn-padding-x / 1.25;
$btn-padding-y-sm:               $btn-padding-y / 2;

$btn-padding-x-lg:               $btn-padding-x * 2;
$btn-padding-y-lg:               $btn-padding-y * 1.125;

$btn-block-spacing-y:            1em;
$btn-toolbar-margin:             1em;

$split-btn-dropdown-toggle-padding-x:     0.5em;
$split-btn-lg-dropdown-toggle-padding-x:  0.75em;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius;
$btn-border-radius-lg:           $border-radius-lg;
$btn-border-radius-sm:           $border-radius-sm;


// Forms

$input-padding-x:                .75em;
$input-padding-y:                .375em;

$input-bg:                       #fff;
$input-bg-disabled:              $gray-300;

$input-color:                    $body-color;
$input-border-color:             $border-color;
$input-btn-border-width:         $border-width;
$input-box-shadow:               none;

$input-border-radius:            $border-radius;
$input-border-radius-lg:         $border-radius-lg;
$input-border-radius-sm:         $border-radius-sm;

$input-border-focus:             $border-color; //currentColor;
$input-box-shadow-focus:         none;

$input-color-placeholder:        #999;

$input-padding-x-sm:             .75em;
$input-padding-y-sm:             .275em;

$input-padding-x-lg:             1.25em;
$input-padding-y-lg:             .75em;

$input-height-border:                   $input-btn-border-width * 2;

$input-height-inner:                    ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2);
$input-height:                          calc(#{$input-height-inner} + #{$input-height-border});

$input-height-inner-sm:                 ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2);
$input-height-sm:                       calc(#{$input-height-inner-sm} + #{$input-height-border});

$input-height-inner-lg:                 ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2);
$input-height-lg:                       calc(#{$input-height-inner-lg} + #{$input-height-border});

$form-group-margin-bottom:       $spacer;

$input-group-addon-bg:           $gray-300;
$input-group-addon-border-color: $input-border-color;

$cursor-disabled:                not-allowed;

// Form validation icons
$form-icon-success: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
$form-icon-warning: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
$form-icon-danger: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-bg:                    #fff;
$dropdown-border-color:          $border-color;
$dropdown-border-width:          $border-width;
$dropdown-divider-bg:            $border-color;
$dropdown-header-color:          $gray-400;

$dropdown-link-color:            $body-color;
$dropdown-link-hover-color:      $dropdown-link-color;
$dropdown-link-hover-bg:         $component-active-bg;
$dropdown-link-active-color:     $dropdown-link-color;
$dropdown-link-active-bg:        $component-active-bg;
$dropdown-link-disabled-color:   $gray-400;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-navbar:            1000;
$zindex-dropdown:          1000;
$zindex-popover:           1060;
$zindex-tooltip:           1070;
$zindex-navbar-fixed:      1030;
$zindex-navbar-sticky:     1030;
$zindex-modal-bg:          1040;
$zindex-modal:             1050;


// Navbar

$navbar-border-radius:              $border-radius;
$navbar-padding-horizontal:         $spacer;
$navbar-padding-vertical:           ($spacer / 2);

$navbar-dark-color:                 rgba(255,255,255,.5);
$navbar-dark-hover-color:           rgba(255,255,255,.75);
$navbar-dark-active-color:          rgba(255,255,255,1);
$navbar-dark-disabled-color:        rgba(255,255,255,.25);

$navbar-light-color:                rgba(0,0,0,.3);
$navbar-light-hover-color:          rgba(0,0,0,.6);
$navbar-light-active-color:         rgba(0,0,0,.8);
$navbar-light-disabled-color:       rgba(0,0,0,.15);


// Navs

$nav-link-padding:                          .5em 1em;
$nav-link-hover-bg:                         $gray-300;

$nav-disabled-link-color:                   $gray-400;
$nav-disabled-link-hover-color:             $gray-400;

$nav-tabs-border-color:                     #ddd;

$nav-tabs-link-border-width:                $border-width;
$nav-tabs-link-hover-border-color:          $gray-300;

$nav-tabs-active-link-hover-bg:             $body-bg;
$nav-tabs-active-link-hover-color:          colors("gray");
$nav-tabs-active-link-hover-border-color:   #ddd;

$nav-tabs-justified-link-border-color:            #ddd;
$nav-tabs-justified-active-link-border-color:     $body-bg;

$nav-pills-border-radius:                   $border-radius;
$nav-pills-active-link-hover-bg:            $component-active-bg;
$nav-pills-active-link-hover-color:         $component-active-color;


// Pagination

$pagination-padding-x:                .75em;
$pagination-padding-y:                .5em;
$pagination-padding-x-sm:             .75em;
$pagination-padding-y-sm:             .275em;
$pagination-padding-x-lg:             1.5em;
$pagination-padding-y-lg:             .75em;


$pagination-color:                     $link-color;
$pagination-bg:                        #fff;
$pagination-border-width:              $border-width;
$pagination-border-color:              #ddd;

$pagination-hover-color:               $link-hover-color;
$pagination-hover-bg:                  $gray-300;
$pagination-hover-border:              #ddd;

$pagination-active-color:              #fff;
$pagination-active-bg:                 theme-color("primary");
$pagination-active-border:             theme-color("primary");

$pagination-disabled-color:            $gray-400;
$pagination-disabled-bg:               #fff;
$pagination-disabled-border:           #ddd;


// Pager

$pager-bg:                             $pagination-bg;
$pager-border-width:                   $border-width;
$pager-border-color:                   $pagination-border-color;
$pager-border-radius:                  1.25em;

$pager-hover-bg:                       $pagination-hover-bg;

$pager-active-bg:                      $pagination-active-bg;
$pager-active-color:                   $pagination-active-color;

$pager-disabled-color:                 $pagination-disabled-color;


// Jumbotron

$jumbotron-padding:              2em;
$jumbotron-bg:                   $gray-300;


// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.
$state-success-text:             rgba(255, 255, 255, 0.75);
$state-success-bg:               theme-color("success");
$state-success-border:           $border-color;

$state-info-text:                rgba(255, 255, 255, 0.75);
$state-info-bg:                  theme-color("info");
$state-info-border:              $border-color;

$state-warning-text:             rgba(255, 255, 255, 0.75);
$state-warning-bg:               theme-color("warning");
$state-warning-border:           $border-color;

$state-danger-text:              rgba(255, 255, 255, 0.75);
$state-danger-bg:                theme-color("danger");
$state-danger-border:            $border-color;


// Cards
$card-spacer-x:            10%;
$card-spacer-y:            .75em;
$card-border-width:        0;
$card-border-radius:       $border-radius;
$card-border-color:        $border-color;
$card-border-radius-inner: $card-border-radius;
$card-cap-bg:              $gray-400;
$card-bg:                  rgba(0,0,0,0);
$card-link-hover-color:    $link-color;


// Tooltips

$tooltip-max-width:           200px;
$tooltip-color:               #fff;
$tooltip-bg:                  #000;
$tooltip-opacity:             .9;

$tooltip-arrow-width:         0.5em;
$tooltip-arrow-color:         $tooltip-bg;


// Popovers

$popover-bg:                          #fff;
$popover-max-width:                   276px;
$popover-border-width:                $border-width;
$popover-border-color:                rgba(0,0,0,.2);

$popover-title-bg:                    darken($popover-bg, 3%);

$popover-arrow-width:                 10px;
$popover-arrow-color:                 $popover-bg;

$popover-arrow-outer-width:           ($popover-arrow-width + 1);
$popover-arrow-outer-color:           fade-in($popover-border-color, 0.05);


// Labels

$label-default-bg:            $gray-400;
$label-primary-bg:            theme-color("primary");
$label-success-bg:            theme-color("success");
$label-info-bg:               theme-color("info");
$label-warning-bg:            theme-color("warning");
$label-danger-bg:             theme-color("danger");

$label-color:                 #fff;
$label-link-hover-color:      #fff;
$label-font-weight:           $font-weight-bold;


// Modals

// Padding applied to the modal body
$modal-inner-padding:         1.25em;

$modal-title-padding:         1.25em;
$modal-title-line-height:     $line-height;

$modal-content-bg:            $content-bg;
$modal-content-border-color:  $border-color;

$modal-backdrop-bg:           rgba(0,0,0,0.85);
$modal-backdrop-opacity:      1;
$modal-header-border-color:   $modal-content-border-color;
$modal-footer-border-color:   $modal-content-border-color;

$modal-lg:                    80%;
$modal-md:                    $modal-lg;
$modal-sm:                    60%;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding:               1.25em;
$alert-border-radius:         $border-radius;
$alert-link-font-weight:      $font-weight-bold;
$alert-border-width:          $border-width;

$alert-success-bg:            $state-success-bg;
$alert-success-text:          $state-success-text;
$alert-success-border:        $state-success-border;

$alert-info-bg:               $state-info-bg;
$alert-info-text:             $state-info-text;
$alert-info-border:           $state-info-border;

$alert-warning-bg:            $state-warning-bg;
$alert-warning-text:          $state-warning-text;
$alert-warning-border:        $state-warning-border;

$alert-danger-bg:             $state-danger-bg;
$alert-danger-text:           $state-danger-text;
$alert-danger-border:         $state-danger-border;


// Progress bars

$progress-bg:                 #f5f5f5;
$progress-bar-color:          #fff;
$progress-border-radius:      $border-radius;

$progress-bar-bg:             theme-color("primary");
$progress-bar-success-bg:     theme-color("success");
$progress-bar-warning-bg:     theme-color("warning");
$progress-bar-danger-bg:      theme-color("danger");
$progress-bar-info-bg:        theme-color("info");


// List group

$list-group-bg:                 #fff;
$list-group-border-color:       #ddd;
$list-group-border-width:       $border-width;
$list-group-border-radius:      $border-radius;

$list-group-hover-bg:           #f5f5f5;
$list-group-active-color:       $component-active-color;
$list-group-active-bg:          $component-active-bg;
$list-group-active-border:      $list-group-active-bg;
$list-group-active-text-color:  lighten($list-group-active-bg, 40%);

$list-group-disabled-color:      $gray-400;
$list-group-disabled-bg:         $gray-300;
$list-group-disabled-text-color: $list-group-disabled-color;

$list-group-link-color:         #555;
$list-group-link-hover-color:   $list-group-link-color;
$list-group-link-heading-color: #333;


// Image thumbnails

$thumbnail-padding:           .25em;
$thumbnail-bg:                $body-bg;
$thumbnail-border-width:      $border-width;
$thumbnail-border-color:      #ddd;
$thumbnail-border-radius:     $border-radius;


// Breadcrumbs

$breadcrumb-padding-vertical:   0.5em;
$breadcrumb-padding-horizontal: 0.5em;

$breadcrumb-bg:                 transparent;
$breadcrumb-color:              $gray-400;
$breadcrumb-active-color:       theme-color("dark");
$breadcrumb-divider-color:      $gray-400;
$breadcrumb-divider:            "/";


// Collapse

$collapse-speed:    0.5s;
$collapse-easing:   cubic-bezier(.25, .46, .45, .94);

$collapse-peek-height-sm: 25vh;
$collapse-peek-height-md: 33vh;
$collapse-peek-height-lg: 50vh;

// Fade in/out

$fade-speed:        0.375s;
$fade-easing:       cubic-bezier(.33, 0, .2, 1);

// Slide in/out

$slide-speed:       0.25s;
$slide-easing:      cubic-bezier(.25, .46, .45, .94);

// Carousel

$carousel-text-shadow:                        none;
$carousel-speed:                              $slide-speed;
$carousel-easing:                             $slide-easing;

$carousel-control-color:                      colors("gray");
$carousel-control-bg:                         rgba(0,0,0,0);
$carousel-control-hover-color:                #fff;
$carousel-control-hover-bg:                   rgba(0,0,0,0.35);
$carousel-control-width:                      auto;
$carousel-control-opacity:                    0.15;
$carousel-control-font-size:                  1.25em;


$carousel-indicator-bg:                       rgba(255,255,255,0.65);
$carousel-indicator-border-color:             rgba(0,0,0,0);
$carousel-indicator-active-bg:                rgba(255,255,255,0);
$carousel-indicator-active-border-color:      rgba(255,255,255,1);

$carousel-caption-color:                      #fff;
$carousel-caption-responsive-bg:              $gray-900;
$carousel-caption-responsive-height:          $spacer * 10;


// Close

$close-font-weight:           inherit;
$close-color:                 inherit;
$close-text-shadow:           none;


// Code

$code-color:                  #bd4147;
$code-bg:                     #f7f7f9;

$kbd-color:                   #fff;
$kbd-bg:                      #333;

$pre-bg:                      #f7f7f9;
$pre-color:                   theme-color("dark");
$pre-border-color:            #ccc;
$pre-scrollable-max-height:   340px;
