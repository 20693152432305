.p-accordion {
    margin-bottom: $paragraph-margin-bottom;
    .p-txt-container {
        :last-child {
            // margin-bottom: 0;
        }
    }
}

.p-accordion-toggle {
    cursor: pointer;
}

.p-accordion-toggle-icon {
    // position: relative;
    transform: rotate(135deg);
    transition: transform $transition-collapse-speed $transition-collapse-easing;
    font-size: 1.5em;
    .collapsed & {
        transform: rotate(0deg);
    }
}

.p-accordion-toggle-icon-left {
    // left: -0.25em;
}

.p-accordion-toggle-icon-right {
    // right: -0.25em;
}
