@mixin e-canvas-padding {
    padding-right: $canvas-padding-sm;
    padding-left: $canvas-padding-sm;
    @include media-breakpoint-up(xl) {
        padding-right: $canvas-padding-lg;
        padding-left: $canvas-padding-lg;
    }
}

@mixin e-section-height($height) {
    $section-height: map-get($section-heights, $height);
    min-height: $section-height;
    @include media-breakpoint-down(xs) {
        min-height: $section-height * 1.5;
    }
    @media (min-width: $canvas-max-width + 1px) {
        min-height: ( $canvas-max-width / 100) * e-strip-unit( $section-height);
    }
}

@mixin material-icons($icon: "") {
    content: $icon;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1em;
    vertical-align: -10%;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    font-smoothing: antialiased;
}

@mixin e-bg($e-bg-color: $body-bg, $e-bg-border-color: $border-color, $e-bg-hr-color: $hr-border-color) {
    background: $e-bg-color;
    border-color: $e-bg-border-color;
    hr {
        border-color: $e-bg-hr-color;
    }
}

@mixin e-text($e-txt-color: $body-color, $e-txt-headings-color: $headings-color, $e-txt-link-color: $link-color) {
    color: $e-txt-color;
    h1,
    .h1,
    h2,
    .h2 {
        color: $e-txt-headings-color;
    }
    h3,
    .h3,
    h4,
    .h4 {
        color: $e-txt-color;
    }
    p,
    ul {
        a:not(.btn) {
            color: $e-txt-link-color;
            @if ($e-txt-link-color==$e-txt-color) or ($e-txt-link-color==$e-txt-headings-color) or ($e-txt-link-color==inherit) {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

@mixin e-text-dark {
    @include e-text($yiq-text-dark, $yiq-headings-dark, $yiq-link-dark);
}

@mixin e-text-light {
    @include e-text($yiq-text-light, $yiq-headings-light, $yiq-link-light);
}

@mixin e-bg-light {
    @include e-bg(e-section-color("light", "bg"), e-section-color("light", "border"));
}

@mixin e-bg-dark {
    @include e-bg(e-section-color("dark", "bg"), e-section-color("dark", "border"));
}

@mixin e-section-primary {
    @include e-bg(e-section-color("primary", "bg"), e-section-color("primary", "border"));
    @include e-text(e-section-color("primary", "text"), e-section-color("primary", "headings"), e-section-color("primary", "link"));
}

@mixin e-section-secondary {
    @include e-bg(e-section-color("secondary", "bg"), e-section-color("secondary", "border"));
    @include e-text(e-section-color("secondary", "text"), e-section-color("secondary", "headings"), e-section-color("secondary", "link"));
}

@mixin e-section-tertiary {
    @include e-bg(e-section-color("tertiary", "bg"), e-section-color("tertiary", "border"));
    @include e-text(e-section-color("tertiary", "text"), e-section-color("tertiary", "headings"), e-section-color("tertiary", "link"));
}

@mixin e-section-quaternary {
    @include e-bg(e-section-color("quaternary", "bg"), e-section-color("quaternary", "border"));
    @include e-text(e-section-color("quaternary", "text"), e-section-color("quaternary", "headings"), e-section-color("quaternary", "link"));
}

// Toggles
//
// Used in conjunction with global variables to enable certain theme features.
// Utilities
// @import "mixins/breakpoints";
// @import "mixins/hover";
// @import "mixins/image";
// @import "mixins/badge";
// @import "mixins/resize";
// @import "mixins/screen-reader";
// @import "mixins/size";
// @import "mixins/reset-text";
// @import "mixins/text-emphasis";
// @import "mixins/text-hide";
// @import "mixins/text-truncate";
// @import "mixins/visibility";
// // Components
// @import "mixins/alert";
@import "mixins/buttons";
// @import "mixins/pagination";
@import "mixins/lists";
// @import "mixins/list-group";
// @import "mixins/nav-divider";
@import "mixins/forms";
// @import "mixins/table-row";
// // Skins
// @import "mixins/background-variant";
// @import "mixins/border-radius";
// @import "mixins/box-shadow";
// @import "mixins/gradients";
// @import "mixins/transition";
// // Layout
// @import "mixins/clearfix";
// // @import "mixins/navbar-align";
@import "mixins/grid-framework";
// @import "mixins/grid";
// @import "mixins/float";
