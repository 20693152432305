.p-icontext {
    margin-bottom: $paragraph-margin-bottom;
    :last-child {
        margin-bottom: 0;
    }
    .align-items-center {}
    .p-img {
        margin-bottom: $spacer / 2;
        position: relative;
        padding-top: 100%;
        // http://tympanus.net/codrops/2014/08/19/making-svgs-responsive-with-css/
        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            max-height: 100%;
        }
    }
}

.p-icontext-white {
    svg {
        [id*="Filled_Icon"] {
            fill: $white;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: $white;
            }
        }
    }
}

.p-icontext-black {
    svg {
        [id*="Filled_Icon"] {
            fill: $black;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: $black;
            }
        }
    }
}

.p-icontext-light {
    svg {
        [id*="Filled_Icon"] {
            fill: $gray-400;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: $gray-400;
            }
        }
    }
}

.p-icontext-dark {
    svg {
        [id*="Filled_Icon"] {
            fill: $gray-600;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: $gray-600;
            }
        }
    }
}

.p-icontext-brand-primary {
    svg {
        [id*="Filled_Icon"] {
            fill: theme-color("primary");
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: theme-color("primary");
            }
        }
    }
}

.p-icontext-brand-secondary {
    svg {
        [id*="Filled_Icon"] {
            fill: theme-color("secondary");
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: theme-color("secondary");
            }
        }
    }
}

.p-icontext-brand-tertiary {
    svg {
        [id*="Filled_Icon"] {
            fill: theme-color("tertiary");
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: theme-color("tertiary");
            }
        }
    }
}

.p-icontext-brand-quaternary {
    svg {
        [id*="Filled_Icon"] {
            fill: theme-color("quaternary");
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: theme-color("quaternary");
            }
        }
    }
}

.p-icontext-auto,
.p-icontext .p-img[style] {
    svg {
        [id*="Filled_Icon"] {
            fill: currentColor;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: currentColor;
            }
        }
    }
}
