.floorplans-filter {
    .floorplans-category {
        input[type="checkbox"] {
            display: none;
        }
        label {
            min-height: 210px;
            width: 100%;
            border-radius: 0.5em;
            background-color: $olive-green;
    
            &.selected {
                background-color: theme-color("primary");
            }
    
            &:hover {
                cursor: pointer;
            }
    
            img {
                transition-duration: 0.2s;
                transform-origin: 50% 50%;
            }
        }
    }
    
    .form-control {
        border: 1px solid $olive-green;
        color: $olive-green;
    }
    .btn {
        background: $olive-green linear-gradient(180deg, $olive-green, $olive-green) repeat-x;
        border-color: $olive-green;
    }
    a {
        color: $olive-green;
    }
}

.floorplans {
    .card-showmore {
        background-color: $olive-green;

        #floorplan-img-big:hover {
            cursor: pointer;
        }
    }
}