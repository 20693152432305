.p-tile {
    padding: 0;
    line-height: $line-height-base * 0.9;
    @include media-breakpoint-down(lg) {
        line-height: $line-height-sm;
    }

    &.p-tile-txt-small {
        .p-txt-container {
            font-size: $font-size-sm;
        }
    }

    &.p-tile-txt-medium {
        .p-txt-container {
            font-size: $font-size-base;
        }
    }

    &.p-tile-txt-large {
        .p-txt-container {
            font-size: $font-size-lg;
        }
    }

    .p-tile-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        height: 100%;
        overflow: hidden;
    }
    .p-txt-container {
        position: relative;
        z-index: 2;
        width: 100%;
        padding: $spacer * 1.4 $spacer * 1;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 0;

            & + p, & + a {
                margin-top: $spacer * 0.6;
            }
        }

        p {
            margin-bottom: $spacer * 0.75;
             &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.p-tile-link {
        &:hover {
            .p-img {
                transform: $transform-scale-zoom;
            }
        }
    }

    a.p-tile-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
    }
}

//Image tile specific styling
.p-tile-img {
    .p-img-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        overflow: hidden;
    }
    .p-img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        transition: transform $transition-fade-speed;
        backface-visibility: hidden;
        &:not(.p-tile-gradient-disabled)::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(22deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    .p-img-left-top {
        background-position: 0 0;
    }
    .p-img-left-middle {
        background-position: 0 50%;
    }
    .p-img-left-bottom {
        background-position: 0 100%;
    }
    .p-img-center-top {
        background-position: 50% 0;
    }
    .p-img-center-middle {
        background-position: 50% 50%;
    }
    .p-img-center-bottom {
        background-position: 50% 100%;
    }
    .p-img-right-top {
        background-position: 100% 0;
    }
    .p-img-right-middle {
        background-position: 100% 50%;
    }
    .p-img-right-bottom {
        background-position: 100% 100%;
    }
}

// Heights
.p-tile-height-auto {
    .p-txt-container {
        position: static;
    }
}

.p-tile-height-xs {
    height: 25vh;
    min-height: 1em * 25 / 2;
}

.p-tile-height-sm {
    height: 33.33333vh;
    min-height: 1em * 33.33333 / 2;
}

.p-tile-height-md {
    height: 50vh;
    min-height: 1em * 50 / 2;
}

.p-tile-height-lg {
    height: 66.66666vh;
    min-height: 1em * 66.66666 / 2;
}

.p-tile-height-xl {
    height: 75vh;
    min-height: 1em * 75 / 2;
}

.p-tile-height-fullscreen {
    height: 100vh;
    @include media-breakpoint-up(sm) {
        min-height: 1em * 100 / 2;
    }
}

//Colors
.p-tile-bg-white {
    @include e-text-dark;
    background-color: #fff;
}

.p-tile-bg-black {
    @include e-text-light;
    background-color: #000;
}

.p-tile-bg-light {
    @include e-text-dark;
    @include e-bg-light;
}

.p-tile-bg-dark {
    @include e-text-light;
    @include e-bg-dark;
}

.p-tile-bg-brand-primary {
    @include e-section-primary;
}

.p-tile-bg-brand-secondary {
    @include e-section-secondary;
}

.p-tile-bg-brand-tertiary {
    @include e-section-tertiary;
}

.p-tile-bg-brand-quaternary {
    @include e-section-quaternary;
}

.p-tile-txt-dark {
    @include e-text-dark;
}

.p-tile-txt-light {
    @include e-text-light;
}

@include media-breakpoint-down(sm) {
    .p-tile-order-first {
        order: -1;
    }
    .p-tile-order-last {
        order: 1;
    }
}

// Content alignment
.p-tile {
    &.p-tile-center-top {
        .p-tile-container {
            align-items: flex-start;
        }
        .p-txt-wrapper {
            justify-content: center;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 65%);
        }
    }
    &.p-tile-center-middle {
        .p-tile-container {
            align-items: center;
        }
        .p-txt-wrapper {
            justify-content: center;
        }
        .p-img-container .p-img::after {
            background: rgba(0, 0, 0, 0.55);
        }
    }
    &.p-tile-center-bottom {
        .p-tile-container {
            align-items: flex-end;
        }
        .p-txt-wrapper {
            justify-content: center;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 65%);
        }
    }
    &.p-tile-left-top {
        .p-tile-container {
            align-items: flex-start;
        }
        .p-txt-wrapper {
            justify-content: flex-start;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(157deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    &.p-tile-left-middle {
        .p-tile-container {
            align-items: center;
        }
        .p-txt-wrapper {
            justify-content: flex-start;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    &.p-tile-left-bottom {
        .p-tile-container {
            align-items: flex-end;
        }
        .p-txt-wrapper {
            justify-content: flex-start;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(22deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    &.p-tile-right-top {
        .p-tile-container {
            align-items: flex-start;
        }
        .p-txt-wrapper {
            justify-content: flex-end;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(202deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    &.p-tile-right-middle {
        .p-tile-container {
            align-items: center;
        }
        .p-txt-wrapper {
            justify-content: flex-end;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    &.p-tile-right-bottom {
        .p-tile-container {
            align-items: flex-end;
        }
        .p-txt-wrapper {
            justify-content: flex-end;
        }
        .p-img-container .p-img::after {
            background: linear-gradient(338deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }
    &.p-tile-gradient-disabled {
        .p-img-container .p-img::after {
            background: none;
        }
    }
}