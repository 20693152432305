// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.
.badge {
    // Empty badges collapse automatically
    transition: opacity $transition-fade-speed $transition-fade-easing;
    &:empty {
        opacity: 0;
        display: inline-block;
    }
}

// Quick fix for badges in buttons
.btn .badge {}

// Pill badges
///
// Make them extra rounded with a modifier to replace v3's badges.
.badge-pill {
    min-width: ($badge-padding-y * 2) + 1;
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).
