.p-banner {
    a {
        &.arrow-right {
            text-decoration: none;
        }
    }
}

.p-banner-txt-center-middle {
    &::before {
        background: rgba(0, 0, 0, 0.45);
    }
}