button.close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 1em;
    opacity: 1;
    cursor: pointer;
    &:hover {
        opacity: inherit;
    }
}
