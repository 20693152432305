.e-cta {
    font-size: $font-size-sm;
    background: $cta-bg;
    box-shadow: $shadow-sm;
    z-index: $zindex-fixed - 1;
    transform: translateY(0);
    transition: transform $transition-slide-speed $transition-slide-easing $transition-slide-speed;
    will-change: transform;
    &.is-hidden {
        transform: translateY(-110%);
        transition: transform $transition-slide-speed $transition-slide-easing;
    }
    .row {
        margin: 0;
        >[class*="col-"] {
            padding: 0;
        }
    }
    p {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: ($spacer / 4) 0;
        font-size: $font-size-sm;
        line-height: $line-height-sm;
        @include media-breakpoint-down(xs) {
            justify-content: space-around;
        }
        @include media-breakpoint-up(sm) {
            font-size: $font-size-base;
        }
    }
    a {
        display: inline-block;
        white-space: nowrap;
        text-decoration: none;
        color: $cta-link-color;
        padding: $btn-padding-y-sm $btn-padding-x-sm / 2;
        @include media-breakpoint-up(md) {
            margin-right: $spacer;
            margin-left: $spacer;
        }
        .material-icons {
            transform: scale(1.25) translateY(0.0675em);
            margin-right: $spacer * 0.375;
            color: $cta-icon-color;
        }
        &:hover {
            color: $cta-link-hover-color;
            .material-icons {
                color: $cta-icon-hover-color;
            }
        }
        &:first-child {
            @include media-breakpoint-up(sm) {
                @include button-size($btn-padding-y-sm, $btn-padding-x-sm, 1em, $btn-line-height, $btn-border-radius-sm);
                @include button-variant($cta-btn-color);
                @include transition($btn-transition);
                font-family: $btn-font-family;
                cursor: pointer;
                font-weight: $btn-font-weight;
                border: $input-btn-border-width solid transparent;
                text-decoration: none;
                margin-top: $spacer / 4;
                margin-bottom: $spacer / 4;
                margin-right: $spacer;
                @include media-breakpoint-up(md) {
                    margin-right: $spacer * 2;
                }
                .material-icons {
                    display: none;
                }
            }
        }
    }
}
