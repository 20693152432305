// @import "utilities/align";
// @import "utilities/background";
.bg-black {
    background-color: $black !important;
}

.bg-left-top {
    background-position: 0 0 !important;
}

.bg-left-middle {
    background-position: 0 50% !important;
}

.bg-left-bottom {
    background-position: 0 100% !important;
}

.bg-center-top {
    background-position: 50% 0 !important;
}

.bg-center-middle {
    background-position: 50% 50% !important;
}

.bg-center-bottom {
    background-position: 50% 100% !important;
}

.bg-right-top {
    background-position: 100% 0 !important;
}

.bg-right-middle {
    background-position: 100% 50% !important;
}

.bg-right-bottom {
    background-position: 100% 100% !important;
}

.bg-cover {
    background-size: cover !important;
}

.bg-contain {
    background-size: contain !important;
}

// @import "utilities/borders";
// @import "utilities/clearfix";
// @import "utilities/display";
// @import "utilities/embed";
// @import "utilities/flex";
// @import "utilities/float";
// @import "utilities/position";
@each $size,
$length in $spacers {
    $scale: e-strip-unit($spacer) * 2;
    .pos-t-#{$size} {
        top: $scale * e-strip-unit($length) * 1%;
    }
    .pos-r-#{$size} {
        right: $scale * e-strip-unit($length) * 1%;
    }
    .pos-b-#{$size} {
        bottom: $scale * e-strip-unit($length) * 1%;
    }
    .pos-l-#{$size} {
        left: $scale * e-strip-unit($length) * 1%;
    }
}

// @import "utilities/screenreaders";
// @import "utilities/sizing";
// @import "utilities/spacing";
// @import "utilities/visibility";
// @import "utilities/text";
// Weight and italics
// Contextual colors
.text-black {
    color: $black !important;
}

.text-link {
    color: $link-color !important;
}

.text-auto {
    color: inherit !important;
}

.text-muted {
    opacity: $text-muted;
}

.text-monospace {
    font-family: $font-family-monospace;
}

.text-decoration-none {
    &,
    &:hover {
        text-decoration: none;
    }
}

.line-height-sm {
    line-height: $line-height-sm;
}

.shadow-none {
    box-shadow: none !important;
}

// Espresso text color schemes
.e-text-dark {
    @include e-text($yiq-text-dark, $yiq-headings-dark, $yiq-link-dark);
}

.e-text-light {
    @include e-text($yiq-text-light, $yiq-headings-light, $yiq-link-light);
}

// Espresso section height
@each $key,
$value in $section-heights {
    .e-section-height-#{$key} {
        @include e-section-height($key);
    }
}

.e-section-height-fullscreen {
    min-height: 100vh;
}

// Espresso section variants
@each $section-name,
$values in $section-colors {
    .e-section-#{$section-name} {
        @include e-bg(e-section-color($section-name, "bg"), e-section-color($section-name, "border"));
        @include e-text(e-section-color($section-name, "text"), e-section-color($section-name, "headings"), e-section-color($section-name, "link"));
    }
}
