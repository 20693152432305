@include media-breakpoint-down($nav-hamburger-breakpoint) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-utilities-primary {
        // All levels
        .nav {
            color: $header-color;
            flex-direction: column;
            margin: 0 0 ($spacer * 0.5) ($spacer * 1.25);
        }
        .nav-item {}
        .nav-link {
            display: block;
            padding: ($spacer * 0.5) ($spacer * 0);
            color: rgba($header-color, 0.85);
            background: none;
            white-space: normal;
        }
        .e-nav-pageicon {
            transform: translateY(0.0675em) scale(1.125);
            width: 1em;
        }
        .e-nav-pageicon-language {
            display: inline-block;
            height: 1em;
            width: 1em;
            vertical-align: middle;
            border-radius: 50%;
            // border: $border-width solid $border-color;
            // box-shadow: $shadow-xs;
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            // background-clip: padding-box;
            // background-color: $dropdown-bg;
            transform: translateY(-0.0675em);
        }
        .e-nav-pageicon + .e-nav-pagename,
        .e-nav-pageicon-language + .e-nav-pagename {
            margin-left: 0.875em;
        }
        .e-nav-pageicon-dropdown {
            display: none;
        }
        .dropdown-toggle {
            //Dropdown arrow
            &::after {
                display: none;
            }
        }
        // Level #1
        > .nav {
            flex-direction: column-reverse;
            margin: $spacer ($grid-gutter-width / 2) ($spacer * 5);
            padding: 0 $canvas-padding-sm;
            > .nav-item {
                > .nav-link {}
            }
        }
    }
}

@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-utilities-primary {
        font-size: 0.675em;
        // All levels
        .nav-link {
            color: inherit;
        }
        .e-nav-pageicon {}
        .e-nav-pageicon-language {
            display: inline-block;
            height: 1.25em;
            width: 1.25em;
            vertical-align: middle;
            // border: $border-width solid $border-color;
            border-radius: 50%;
            // box-shadow: $shadow-sm;
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            // background-clip: padding-box;
            // background-color: $dropdown-bg;
            transform: translateY(-0.0675em);
        }
        .e-nav-pageicon + .e-nav-pagename {
            margin-left: 0.25em;
        }
        .e-nav-pageicon-language + .e-nav-pagename {
            margin-left: 0.5em;
        }
        .e-nav-pagename {
            &.is-hidden {
                display: none;
                + .e-nav-pageicon-dropdown {
                    display: none;
                }
            }
        }
        .e-nav-pageicon-dropdown {
            font-size: 1.25em;
            margin-left: 0.125em;
            vertical-align: -27%;
        }
        .dropdown-toggle {
            //Dropdown arrow
            &::after {
                display: none;
            }
        }
        .dropdown-menu {
            font-size: 1em;
            margin-top: -1px;
            min-width: 100%;
            .nav-link {
                padding: ($spacer * 0.25) ($spacer * 1);
            }
        }
        // Level #1
        > .nav {
            justify-content: flex-end;
            text-align: right;
            >.nav-item {
                display: inline-block;
                &:last-child {
                    .dropdown-menu {
                        left: auto;
                        right: 0;
                    }
                }
                >.nav-link {
                    padding: ($spacer * 0.25) 0 ($spacer * 0.375) ($spacer * 1);
                    opacity: 0.65;
                    transition: opacity $transition-fade-speed $transition-fade-easing;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
