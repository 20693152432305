// Retrieve color from map ie. `color(primary, base)`
@function e-section-color($section-name, $color-variant) {
    // Map inception
    @return map-get(map-get($section-colors, $section-name), $color-variant);
}

// Remove the unit of a length
// @param {Number} $number - Number to remove unit from
// @return {Number} - Unitless number
@function e-strip-unit($number) {
    @if type-of($number)=='number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
    @return $number;
}

// Shadows
//
// Umbra, Penumbra and ambient shadows from https://material.io/guidelines/resources/shadows.html
@function e-shadow($size: "md", $color: $black, $direction: "down") {
    @if $direction=="up" {
        $direction: -1;
    }
    @else {
        $direction: 1;
    }
    @if $size=="xs" {
        @return 0 (2px * $direction) 1px -1px rgba($color, .14),
        0 (1px * $direction) 1px 0 rgba($color, .2),
        0 (1px * $direction) 3px 0 rgba($color, .12); //.mdl-shadow--1dp
    }
    @else if $size=="sm" {
        @return 0 (2px * $direction) 2px 0 rgba($color, .14),
        0 (3px * $direction) 1px -2px rgba($color, .2),
        0 (1px * $direction) 5px 0 rgba($color, .12); //.mdl-shadow--2dp
    }
    @else if $size=="md" {
        @return 0 (4px * $direction) 5px 0 rgba($color, .14),
        0 (1px * $direction) 10px 0 rgba($color, .12),
        0 (2px * $direction) 4px -1px rgba($color, .2); //.mdl-shadow--4dp
    }
    @else if $size=="lg" {
        @return 0 (8px * $direction) 10px 1px rgba($color, .14),
        0 (3px * $direction) 14px 2px rgba($color, .12),
        0 (5px * $direction) 5px -3px rgba($color, .2); //.mdl-shadow--8dp
    }
    @else if $size=="xl" {
        @return 0 (7px * $direction) 8px -4px rgba($color, .14),
        0 (12px * $direction) 17px 2px rgba($color, .12),
        0 (5px * $direction) 22px 4px rgba($color, .2); //.mdl-shadow--12dp
    }
}

// Color contrast
@function color-yiq($color, $light: $yiq-text-light, $dark: $yiq-text-dark) {
    $r: red($color);
    $g: green($color);
    $b: blue($color);
    $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;
    @if ($yiq >=150) {
        @return $dark;
    }
    @else {
        @return $light;
    }
}
