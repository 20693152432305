@include media-breakpoint-down(xs) {
    .e-header-hamburger {
        // .e-logo-img-primary {
        //     display: none;
        // }
        // .e-logo-img-secondary {
        //     display: block;
        // }
    }
    .e-nav {
        // .e-logo-img-primary {
        //     display: block;
        // }
        // .e-logo-img-secondary {
        //     display: none;
        // }
    }
    .modal-header {
        // .e-logo-img-primary {
        //     display: none;
        // }
        // .e-logo-img-secondary {
        //     display: block;
        // }
    }
}

@include media-breakpoint-down($nav-hamburger-breakpoint) {
    .e-logo {
        display: flex;
        align-items: center;
        .e-nav & {
            padding-left: e-strip-unit($grid-gutter-width / 2) * 1vw + $canvas-padding-sm;
            position: sticky;
            top: 0;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 100vw;
                background: $header-bg;
            }
        }
    }
    .e-logo-link {
        position: relative;
        display: block;
        margin: $header-logo-spacing-small 0;
        height: $header-logo-height-small;
    }
    .e-logo-img {
        display: block;
        height: 100%;
    }
    .e-logo-img-secondary {
        display: none;
    }
    .e-logo-tagline {
        display: none;
    }
}

//Header styling for larger breakpoints.
@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    .e-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        .e-logo-link {
            position: relative;
            display: block;
            margin: $header-logo-spacing-large 0;
            height: $header-logo-height-large;
        }
        .e-logo-img {
            display: block;
            height: 100%;
            &.e-logo-img-secondary {
                display: none;
            }
        }
        .e-logo-tagline {
            display: none;
        }
    }
}
