.e-search-form-container {
    position: sticky;
    top: 0;
    z-index: 2;
}

.e-search-input-group {
    position: relative;
    display: flex;
    border: $input-btn-border-width solid $input-border-color;
    border-radius: $btn-border-radius;
    background: $input-bg;
    // :first-child {
    //     @include border-right-radius(0);
    // }
    // :last-child {
    //     @include border-left-radius(0);
    // }
}

.e-search-label {
    // TODO: refactor using util classes.
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4em;
    margin: 0;
    .material-icons {
        font-size: 1.5em;
        vertical-align: -26%;
    }
}

.e-search-input {
    width: 100%;
    .e-search-input-group & {
        &,
        &:focus,
        &:hover {
            outline: none;
            // font-size: 1.5em !important;
            @media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
                font-size: 1.5em !important;
            }
        }
    }
    &:-webkit-autofill {
        transition: none;
        -webkit-box-shadow: 0 0 0 5em $input-bg inset;
        -webkit-text-fill-color: $gray-900;
    }
}

.e-search-clear {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4em;
    opacity: 0.85;
    cursor: pointer;
    transition: opacity $transition-fade-speed $transition-fade-easing;
    .material-icons {
        font-size: 1.5em;
    }
    .e-search-input-group.is-empty & {
        opacity: 0;
        pointer-events: none;
    }
    .e-search-input-group.has-focus & {}
}

.e-search-submit {
    border: 0;
    box-shadow: none;
    font-size: 1em;
    padding: 0.5em 1em;
    margin: 0.25em 0.25em 0.25em 0;
    //@include border-left-radius(0);
    transition: opacity $transition-fade-speed $transition-fade-easing;
    .material-icons {
        font-size: 1.5em;
        vertical-align: -26%;
    }
    .e-search-input-group.is-empty & {
        opacity: 0.65;
        pointer-events: none;
    }
    .e-search-input-group.has-focus & {}
}

.e-search-result-container {
    position: relative;
    z-index: 1;
    display: none;
    &.is-active {
        display: block;
    }
}

.e-search-result-pages,
.e-search-result-products {
    &.is-empty {
        display: none;
    }
}

.e-search-result-pages {
    line-height: $line-height-base * 0.875;
}

.e-search-loading-overlay {
    min-height: 50vh;
    background: rgba($loading-bg, 0.85);
    transition: opacity $transition-fade-speed / 2 $transition-fade-easing;
    .e-loading-spinner {
        top: 25vh;
    }
}
