// Embedded icons from Open Iconic.
// Released under MIT and copyright 2014 Waybury.
// https://useiconic.com/open
// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.
.custom-control {
    min-height: (1em * $line-height-sm);
    line-height: $line-height-sm;
    display: flex;
    align-items: center;
}

.custom-control-input {
    &:checked ~ .custom-control-indicator {}
    &:focus ~ .custom-control-indicator {}
    &:active ~ .custom-control-indicator {}
    &:disabled {
        ~ .custom-control-indicator {}
        ~ .custom-control-description {}
    }
}

// Custom indicator
//
// Generates a shadow element to create our makeshift checkbox/radio background.
.custom-control-indicator {
    top: (($line-height-sm - $custom-control-indicator-size) / 2);
}

// Checkboxes
//
// Tweak just a few things for checkboxes.
.custom-checkbox {
    cursor: pointer;
    &:hover {
        .custom-control-indicator {
            // box-shadow: $custom-control-indicator-box-shadow, $custom-control-indicator-focus-box-shadow;
            background-color: $custom-control-indicator-checked-bg;
        }
    }
    .custom-control-indicator {
        background-size: $custom-checkbox-indicator-bg-size;
    }
    .custom-control-input:checked ~ .custom-control-indicator {
        // background-image: $custom-checkbox-indicator-icon-checked;
    }
    .custom-control-input:indeterminate ~ .custom-control-indicator {
        // background-color: $custom-checkbox-indicator-indeterminate-bg;
        // background-image: $custom-checkbox-indicator-icon-indeterminate;
    }
}

// Radios
//
// Tweak just a few things for radios.
.custom-radio {
    cursor: pointer;
    &:hover {
        .custom-control-indicator {
            // box-shadow: $custom-control-indicator-box-shadow, $custom-control-indicator-focus-box-shadow;
            background-color: $custom-control-indicator-checked-bg;
        }
    }
    .custom-control-indicator {
        background-size: $custom-radio-indicator-bg-size;
    }
    .custom-control-input:checked ~ .custom-control-indicator {}
}

// Layout options
//
// By default radios and checkboxes are `inline-block` with no additional spacing
// set. Use these optional classes to tweak the layout.
.custom-controls-stacked {
    .custom-control {
        + .custom-control {}
    }
}

// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// http://primercss.io.
//
.custom-select {
    &:focus {
        &::-ms-value {
            // For visual consistency with other platforms/browsers,
            // supress the default white text on blue background highlight given to
            // the selected option text when the (still closed) <select> receives focus
            // in IE and (under certain conditions) Edge.
            // See https://github.com/twbs/bootstrap/issues/19398.
        }
    }
    &[multiple] {}
    &:disabled {}
    // Hides the default caret in IE11
    &::-ms-expand {}
}

.custom-select-sm {}
